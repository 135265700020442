/**
 * @generated SignedSource<<c5c6d0f12f9f0bd49777b33e333ff829>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE";
export type CampaignStage = "ACTIVE" | "COMPLETED" | "DRAFT" | "IN_REVIEW";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING";
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE";
export type Currency = "CAD" | "RUB" | "USD";
export type FileType = "IMAGE" | "OTHER" | "VIDEO";
export type InstagramInsightsMediaPlacement = "AD" | "FEED" | "REEL" | "STORY";
export type PostPublicationStatsIssueReason = "AD_LIMIT_REACHED" | "API_ERROR" | "CONTENT_DELETED" | "INSUFFICIENT_PERMISSIONS" | "INVALID_ACCESS_TOKEN" | "MAINTENANCE" | "NO_EXISTS_ACCESS_TOKEN" | "REGION_BLOCKED" | "SYSTEM_ERROR" | "TOO_MANY_REQUESTS" | "UNKNOWN_ERROR" | "WRONG_PLATFORM";
import { FragmentRefs } from "relay-runtime";
export type PostedCreative_creative$data = {
  readonly adminFavorite: boolean | null | undefined;
  readonly file: {
    readonly height: number | null | undefined;
    readonly id: string;
    readonly secureUrl: string;
    readonly transformations?: {
      readonly autoUrl?: string;
      readonly postThumbnailUrl: string;
    } | null | undefined;
    readonly type: FileType;
    readonly width: number | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly postPublication: {
    readonly averageViewTime: number | null | undefined;
    readonly comments: number | null | undefined;
    readonly cpe: number | null | undefined;
    readonly cpm: number | null | undefined;
    readonly createdAt: any;
    readonly embedUrl: string | null | undefined;
    readonly engagement: number | null | undefined;
    readonly engagementRate: number | null | undefined;
    readonly likes: number | null | undefined;
    readonly mediaCost: number | null | undefined;
    readonly navigation: number | null | undefined;
    readonly placement: InstagramInsightsMediaPlacement | null | undefined;
    readonly platform: CampaignPlatform | null | undefined;
    readonly publicationUrl: string | null | undefined;
    readonly reach: number | null | undefined;
    readonly saves: number | null | undefined;
    readonly shares: number | null | undefined;
    readonly statsIssueReason: PostPublicationStatsIssueReason | null | undefined;
    readonly updatedAt: any | null | undefined;
    readonly videoCompletionRate: number | null | undefined;
    readonly views: number | null | undefined;
  } | null | undefined;
  readonly project: {
    readonly campaign: {
      readonly id: string;
      readonly organization: {
        readonly currency: Currency;
        readonly id: string;
      } | null | undefined;
      readonly stage: CampaignStage;
      readonly type: CampaignType | null | undefined;
    };
    readonly completion: {
      readonly id: string;
    } | null | undefined;
    readonly creator: {
      readonly __typename: string;
      readonly id: string;
      readonly profile: {
        readonly profilePictureFile: {
          readonly transformations?: {
            readonly collageThumbnailUrl: string;
          } | null | undefined;
          readonly url: string | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly tiktokUser?: {
        readonly id: string;
      } | null | undefined;
      readonly type: CreatorType;
      readonly user?: {
        readonly followedByCount: number | null | undefined;
      } | null | undefined;
      readonly username: string;
    } | null | undefined;
    readonly id: string;
    readonly showcasingPermitted: boolean;
  } | null | undefined;
  readonly " $fragmentType": "PostedCreative_creative";
};
export type PostedCreative_creative$key = {
  readonly " $data"?: PostedCreative_creative$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostedCreative_creative">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postThumbnailUrl",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostedCreative_creative",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminFavorite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectCompletion",
          "kind": "LinkedField",
          "name": "completion",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Campaign",
          "kind": "LinkedField",
          "name": "campaign",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Organization",
              "kind": "LinkedField",
              "name": "organization",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "currency",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showcasingPermitted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "creator",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "username",
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CreatorProfile",
              "kind": "LinkedField",
              "name": "profile",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "profilePictureFile",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ImageTransformation",
                          "kind": "LinkedField",
                          "name": "transformations",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "collageThumbnailUrl",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "Image",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "InstagramUser",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "followedByCount",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "InstagramCreator",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": "tiktokUser",
                  "args": null,
                  "concreteType": "TiktokUser",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "TiktokCreator",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PostPublication",
      "kind": "LinkedField",
      "name": "postPublication",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "statsIssueReason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publicationUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "averageViewTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cpe",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cpm",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "engagement",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "engagementRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "comments",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "likes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mediaCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reach",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "saves",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shares",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "videoCompletionRate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "views",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "navigation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "embedUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "platform",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "placement",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "file",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "height",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "width",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secureUrl",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ImageTransformation",
              "kind": "LinkedField",
              "name": "transformations",
              "plural": false,
              "selections": [
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "Image",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "VideoTransformation",
              "kind": "LinkedField",
              "name": "transformations",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "autoUrl",
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "Video",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Creative",
  "abstractKey": null
};
})();

(node as any).hash = "1729e95f0071c643e7ec63a62a26af22";

export default node;
