import React, { useContext, useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';
import { HugeiconsIcon } from '@hugeicons/react';
import { InformationCircleIcon } from '@hugeicons-pro/core-stroke-rounded';
import { useIntl } from 'react-intl';
import { Button, Text } from '@insensepro/ui-kit';

import styles from './PostedCreative.pcss';
import { getColumns } from './util';

import { createDateV4 } from 'Util/dateCreator';
import { amplitude } from 'Helpers/amplitude';
import { getProjectsLink } from 'Util/links';
import { createDate } from 'Util/dateCreator';
import instagramColoredImg from 'Images/general/instagramColored.svg';
import tiktokColoredImg from 'Images/general/tiktokColored.svg';
import insenseIconImg from 'Images/icons/vetted.png';
import { getCreatorData } from 'Util/creatorData';
import Image from 'Components/ui/Image/Image';
import { CREATIVE_PREVIEW } from 'Constants/modals';
import { PostedCreative_creative$key } from 'GraphTypes/PostedCreative_creative.graphql';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { MEDIA } from 'Constants/general';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';
import Tooltip from 'Atoms/Tooltip/Tooltip';

interface Props {
  admin?: boolean;
  creative: PostedCreative_creative$key;
}

const PostedCreative: React.FC<Props> = (props) => {
  const { creative, admin } = props;

  const intl = useIntl();

  const { dispatch: modalDispatch } = useContext(ModalContext);

  const data = useFragment(
    graphql`
      fragment PostedCreative_creative on Creative {
        id
        adminFavorite
        project {
          completion {
            id
          }
          campaign {
            type
          }
        }
        postPublication {
          statsIssueReason
          updatedAt
          publicationUrl
          createdAt
          averageViewTime
          cpe
          cpm
          engagement
          engagementRate
          comments
          likes
          mediaCost
          reach
          saves
          shares
          videoCompletionRate
          views
          navigation
          embedUrl
          platform
          placement
        }
        file {
          id
          type
          height
          width
          secureUrl
          ... on Image {
            transformations {
              postThumbnailUrl
            }
          }
          ... on Video {
            transformations {
              autoUrl
              postThumbnailUrl
            }
          }
        }
        project {
          id
          showcasingPermitted
          creator {
            id
            username
            type
            __typename
            profile {
              profilePictureFile {
                url
                ... on Image {
                  transformations {
                    collageThumbnailUrl
                  }
                }
              }
            }
            ... on InstagramCreator {
              user {
                followedByCount
              }
            }
            ... on TiktokCreator {
              tiktokUser: user {
                id
              }
            }
          }
          campaign {
            id
            organization {
              id
              currency
            }
            stage
          }
        }
      }
    `,
    creative
  );

  const creativeId = data.id;
  const updatedAt = data.postPublication?.updatedAt;
  const creator = data.project?.creator;
  const projectId = data.project?.id || '';
  const campaign = data.project?.campaign;
  const campaignId = campaign?.id || '';
  const campaignStage = campaign?.stage;
  const organizationId = campaign?.organization?.id;

  const postPublication = data.postPublication;
  const publicationUrl = postPublication?.publicationUrl;
  const embedUrl = postPublication?.embedUrl;
  const statsIssueReason = postPublication?.statsIssueReason;
  const platform = postPublication?.platform;

  useEffect(() => {
    if (statsIssueReason) {
      amplitude.sendEvent<554>({
        id: '554',
        category: 'campaign_performance',
        name: 'posted_creative_stats_error',
        param: { creative_id: creativeId, error: statsIssueReason },
      });
    }
  }, [statsIssueReason, creativeId]);

  const file = data.file;

  const igPlacementText = postPublication?.placement
    ? intl.formatMessage({
        id: `creative.subtitle.${postPublication?.placement.toLowerCase()}`,
      })
    : undefined;

  const placement =
    igPlacementText || (postPublication?.platform?.toUpperCase() === 'TIKTOK' ? 'Video' : '');

  const { avaSrc, username, link } = getCreatorData(creator);

  const platformImg =
    creator?.type === 'INSTAGRAM'
      ? instagramColoredImg
      : creator?.type === 'TIKTOK'
      ? tiktokColoredImg
      : undefined;

  const createdAt = postPublication?.createdAt
    ? createDate(postPublication?.createdAt, 'DD MMM. YYYY')
    : '';

  const poster = data.file?.transformations?.postThumbnailUrl;

  const projectLink = getProjectsLink({
    projectId,
    campaignId: campaignStage === 'ACTIVE' ? campaignId : undefined,
    organizationId: admin ? organizationId : undefined,
  });

  const columnsData = getColumns({ creative: data });

  const handlePosterClick = () => {
    amplitude.sendEvent<519>({
      id: '519',
      category: 'campaign_performance',
      name: 'posted_creative_poster_click',
      param: { project_id: projectId, campaign_id: campaignId },
    });

    // TODO: replace modal with drawer as separate TECH DEBT task https://insensepro.atlassian.net/browse/DEV-7083

    const fileData = embedUrl ? { tikTokUrl: embedUrl } : file;
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: {
        name: CREATIVE_PREVIEW,
        attach: {
          projectId,
          list: [
            {
              ...fileData,
              typename: MEDIA,
            },
          ],
        },
      },
    });
  };

  const handleChatClick = () => {
    amplitude.sendEvent<516>({
      id: '516',
      category: 'campaign_performance',
      name: 'posted_creative_chat_click',
      param: { project_id: projectId, campaign_id: campaignId },
    });
  };

  const handleViewClick = () => {
    if (creator?.type === 'TIKTOK') {
      amplitude.sendEvent<517>({
        id: '517',
        category: 'campaign_performance',
        name: 'posted_creative_view_on_tiktok',
        param: { project_id: projectId, campaign_id: campaignId },
      });
    } else {
      amplitude.sendEvent<518>({
        id: '518',
        category: 'campaign_performance',
        name: 'posted_creative_view_on_ig',
        param: { project_id: projectId, campaign_id: campaignId },
      });
    }
  };

  const platformPrefix = platform === 'TIKTOK' ? '.tt' : '.ig';
  const statsError = `creative.posted.stats_error.${statsIssueReason?.toLowerCase()}${platformPrefix}`;

  const handleUsernameClick = () => {
    amplitude.sendEvent<545>({
      id: '545',
      category: 'campaign_performance',
      name: 'creative_username_click',
      param: { creator_id: creator?.id, campaign_id: campaignId },
    });
  };

  return (
    <div className={styles.root}>
      <div
        className={styles.poster}
        style={{ backgroundImage: `url(${poster})` }}
        onClick={handlePosterClick}
      />
      <div className={styles.content}>
        <div className={styles.details}>
          <div className={styles.avatarWrap}>
            <Image src={avaSrc} type="round" size={39} imgClassName={styles.ava} />
            {platformImg && (
              <div className={styles.platform}>
                <img src={platformImg} className={styles.platformImg} />
              </div>
            )}
          </div>
          <div>
            <div className={styles.usernameWrap}>
              <SmartLink path={link} target="_blank" onClick={handleUsernameClick}>
                <Text type="b2" weight="extraBold" color="textMainLink" text={`@${username}`} />
              </SmartLink>
              <Image src={insenseIconImg} size={20} />
            </div>
            {createdAt && (
              <Text
                type="p"
                weight="medium"
                color="textMainTextTertiary"
                msg="creative.posted_at"
                formatValues={{ placement, date: createdAt }}
              />
            )}
          </div>
          <div className={styles.spacer} />
          {statsIssueReason && (
            <div className={styles.updateErrorWrap}>
              <Text
                msg="creative.posted.no_new_data"
                formatValues={{ date: createDateV4(updatedAt) }}
                className={styles.updateError}
              />
              <Tooltip id={`${creativeId}-stats-data`} tooltipMsg={statsError} place="top-end">
                <HugeiconsIcon icon={InformationCircleIcon} size={16} />
              </Tooltip>
            </div>
          )}
        </div>
        <div className={styles.stats}>
          {columnsData.map((column, columnIndex) => {
            return (
              <div className={styles.column} key={columnIndex}>
                {column.map((row) => {
                  return (
                    <div key={row.id} className={styles.row}>
                      <HugeiconsIcon icon={row.icon} size={16} color="#505555" />
                      <Text type="b2" weight="regular" color="textMainTextTertiary" msg={row.msg} />
                      <div className={styles.spacer} />
                      <Text type="b2" weight="bold" color="textMainDefault" text={row.value} />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className={styles.controls}>
          <SmartLink path={projectLink} onClick={handleChatClick}>
            <Button type="secondary" style="outlined" size="small" msg="creative.open_chat.btn" />
          </SmartLink>
          <SmartLink path={publicationUrl} onClick={handleViewClick}>
            <Button
              type="secondary"
              style="outlined"
              size="small"
              leftElement={<img src={platformImg} />}
              msg={
                creator?.type === 'TIKTOK'
                  ? 'creative.view_on_tiktok'
                  : 'creative.view_on_instagram'
              }
            />
          </SmartLink>
        </div>
      </div>
    </div>
  );
};

export default PostedCreative;
