import React, { PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { HistoryReferrerListener } from 'Hooks/useHistoryReferrer';
import { SIGNIN_ADVERTISER_ROUTE, SIGNUP_COMPLETE_ADVERTISER_ROUTE } from 'Constants/general';
import { Auth0Context } from 'Containers/Auth0/Auth0Context';
import FullscreenLoader from 'Molecules/FullscreenLoader/FullscreenLoader';
import { firebaseClient } from 'Containers/Auth0/Auth0Context/utils';

const PrivateRoute: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { isRegisteredUser, isAuthenticatedUser } = useContext(Auth0Context);
  const authService = firebaseClient.authClient;
  const navigate = useNavigate();

  useEffect(() => {
    authService?.onAuthStateChanged((user) => {
      if (!user) {
        navigate('/signin');
      }
    });
  }, [authService]);

  const searchParams = useMemo<SearchParamsType>(() => {
    const result: SearchParamsType = {};
    result[SIGNIN_ADVERTISER_ROUTE] = new URLSearchParams({
      redirect_url: `${window.location.pathname}${window.location.search}`,
    });
    return result;
  }, []);
  if (isAuthenticatedUser && isRegisteredUser === false) {
    return <Navigate to={SIGNUP_COMPLETE_ADVERTISER_ROUTE} />;
  }

  if (isRegisteredUser) {
    return <HistoryReferrerListener>{children}</HistoryReferrerListener>;
  }

  if (isRegisteredUser === null) {
    return <FullscreenLoader />;
  }

  return (
    <Navigate
      to={`${SIGNIN_ADVERTISER_ROUTE}${
        searchParams[SIGNIN_ADVERTISER_ROUTE]
          ? `?${searchParams[SIGNIN_ADVERTISER_ROUTE].toString()}`
          : ''
      }`}
    />
  );
};

export default PrivateRoute;

type SearchParamsType = {
  [SIGNIN_ADVERTISER_ROUTE]?: URLSearchParams;
};
